import { createApp, reactive, ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import DashboardPlugin from './dashboard-plugin';
import App from './App.vue';
import routes from './routes/routes';
import VueLogger from 'vuejs3-logger';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';


// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === 'production';

const app = createApp( App );

const fsProducts = reactive({ items: {}});
const fsProductsRef = ref(fsProducts);

const initFsProducts = () => {
	app.provide('$fsProducts', fsProductsRef);
};

window.setFSGlobal = data => {
	let { items } = data.groups[0];
	fsProducts.items = items;
	fsProductsRef.value = fsProducts;
};

initFsProducts();

const logOptions = {
	isEnabled: ! isProduction,
	stringifyArguments: false,
	showLogLevel: false,
	showMethodName: false,
	separator: '|',
	showConsoleColors: true
};

window.onload = function () {
	// configure router

	const router = createRouter({
		history: createWebHistory(),
		routes
	});

	router.beforeEach(async (to, from) => {

		if (
			// make sure the user is authenticated
			to.meta.requires_auth && window.optml_data.is_logged_in !== 'yes'
		) {
			let path = { name: 'Login' };
			if(optml_data.lang.length > 0 && !('lang' in to.query)){
				path.query = { lang: optml_data.lang };
			}
			return path;
		}
		if( optml_data.lang.length > 0 && window.optml_data.is_logged_in !== 'yes' && !('lang' in to.query)){
			return {
				...to,
				query: {
					...to.query,
					lang: optml_data.lang
				}
			};
		}
	});
	if ( '' !== window.optmlSentryDNS ) {
		Sentry.init({
			app,
			dsn: window.optmlSentryDNS,
			environment: '' !== window.optmlSentryEnv ? window.optmlSentryEnv : 'production_dashboard',
			integrations: [
				new Sentry.BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation( router ),
				}),
				new Sentry.Replay(),
			],
			tracesSampleRate: 1.0,
			allowUrls: [
				/^https?:\/\/web\.test/,
				/^https:\/\/staging-dashboard\.optimole\.com/,
				/^https:\/\/dashboard\.optimole\.com/
			],
			ignoreErrors: [
				/Loading chunk [\w]+ failed/
			],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	}

	const pinia = createPinia();

	app.use(pinia)
		.use( router )
		.use( DashboardPlugin )
		.use( VueLogger, logOptions )
		.mount('#optimole');
};
