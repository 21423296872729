// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import './polyfills';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';

// asset imports
import './assets/sass/tailwind.scss';

// library auto imports
import 'es6-promise/auto';

export default {
	install( app ) {
		app.use( GlobalDirectives );
	}
};
